export default {
  items: [
    {
      name: 'APP',
      url: '/app',
      icon: 'fa fa-mobile',
      /*badge: {
          variant: 'info',
          text: 'NEW'
      }*/
    },

    {
      title: true,
      name: 'CMS',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Home page',
      url: '/home',
      icon: 'fa fa-home',
      badge: {
        variant: '',
        text: ''
      },
    },
    {
      name: 'Profilo aziendale',
      url: '/profile',
      icon: 'fa fa-briefcase',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'Sartorialità',
      url: '/tailoring',
      icon: 'fa fa-scissors',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'News',
      url: '/news',
      icon: 'fa fa-newspaper-o',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'Video',
      url: '/video',
      icon: 'fa fa-youtube',
      badge: {
        variant: '',
        text: ''
      }
    },

    {
      title: true,
      name: 'Prodotti',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Catalogo',
      url: '/catalog',
      icon: 'fa fa-book',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'Listino',
      url: '/listing',
      icon: 'fa fa-euro',
      badge: {
        variant: 'info',
        text: 'Privato'
      }
    },
    {
      name: 'Categorie',
      url: '/category',
      icon: 'fa fa-folder-open',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'Prodotti',
      url: '/product',
      icon: 'icon-basket-loaded',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      title: true,
      name: 'Finiture',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Categorie Finiture',
      url: '/material-category',
      icon: 'fa fa-folder-open',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
      name: 'Finiture',
      url: '/material',
      icon: 'fa fa-flask',
      badge: {
        variant: '',
        text: ''
      }
    },
    {
        title: true,
        name: 'Strumenti',
        wrapper: {            // optional wrapper object
          element: '',        // required valid HTML5 element tag
          attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
        },
        class: ''             // optional class names space delimited list for title item ex: "text-center"
      },
      {
        name: 'Push Notification',
        url: '/push-notification',
        icon: 'fa fa-mobile-phone',
        badge: {
          variant: '',
          text: ''
        }
      }
  ]
};
