const Config = {
  // apiEndpoint: 'http://172.21.21.110:5050/',
  // assetsBaseUrl: 'http://172.21.21.110:5050/'

  //apiEndpoint: 'http://localhost:5050/',
  //assetsBaseUrl: 'http://localhost:5050/'
  apiEndpoint: process.env.NODE_ENV === 'development' ? 'http://localhost:5050/' : 'https://adminapi.dea-system.it/',
  assetsBaseUrl: process.env.NODE_ENV === 'development' ? 'http://localhost:5050/' : 'https://adminapi.dea-system.it/'
};

export default Config;
