import React from 'react';
import Loadable from 'react-loadable'

import DefaultLayout from './containers/DefaultLayout';

function Loading() {
  return <div>Loading...</div>;
}

// eslint-disable-next-line
const Breadcrumbs = Loadable({
  loader: () => import('./views/Base/Breadcrumbs'),
  loading: Loading,
});

// eslint-disable-next-line
const Cards = Loadable({
  loader: () => import('./views/Base/Cards'),
  loading: Loading,
});

// eslint-disable-next-line
const Carousels = Loadable({
  loader: () => import('./views/Base/Carousels'),
  loading: Loading,
});

// eslint-disable-next-line
const Collapses = Loadable({
  loader: () => import('./views/Base/Collapses'),
  loading: Loading,
});

// eslint-disable-next-line
const Dropdowns = Loadable({
  loader: () => import('./views/Base/Dropdowns'),
  loading: Loading,
});

// eslint-disable-next-line
const Forms = Loadable({
  loader: () => import('./views/Base/Forms'),
  loading: Loading,
});

// eslint-disable-next-line
const Jumbotrons = Loadable({
  loader: () => import('./views/Base/Jumbotrons'),
  loading: Loading,
});

// eslint-disable-next-line
const ListGroups = Loadable({
  loader: () => import('./views/Base/ListGroups'),
  loading: Loading,
});

// eslint-disable-next-line
const Navbars = Loadable({
  loader: () => import('./views/Base/Navbars'),
  loading: Loading,
});

// eslint-disable-next-line
const Navs = Loadable({
  loader: () => import('./views/Base/Navs'),
  loading: Loading,
});

// eslint-disable-next-line
const Paginations = Loadable({
  loader: () => import('./views/Base/Paginations'),
  loading: Loading,
});

// eslint-disable-next-line
const Popovers = Loadable({
  loader: () => import('./views/Base/Popovers'),
  loading: Loading,
});

// eslint-disable-next-line
const ProgressBar = Loadable({
  loader: () => import('./views/Base/ProgressBar'),
  loading: Loading,
});

// eslint-disable-next-line
const Switches = Loadable({
  loader: () => import('./views/Base/Switches'),
  loading: Loading,
});

// eslint-disable-next-line
const Tables = Loadable({
  loader: () => import('./views/Base/Tables'),
  loading: Loading,
});

// eslint-disable-next-line
const Tabs = Loadable({
  loader: () => import('./views/Base/Tabs'),
  loading: Loading,
});

// eslint-disable-next-line
const Tooltips = Loadable({
  loader: () => import('./views/Base/Tooltips'),
  loading: Loading,
});

// eslint-disable-next-line
const BrandButtons = Loadable({
  loader: () => import('./views/Buttons/BrandButtons'),
  loading: Loading,
});

// eslint-disable-next-line
const ButtonDropdowns = Loadable({
  loader: () => import('./views/Buttons/ButtonDropdowns'),
  loading: Loading,
});

// eslint-disable-next-line
const ButtonGroups = Loadable({
  loader: () => import('./views/Buttons/ButtonGroups'),
  loading: Loading,
});

// eslint-disable-next-line
const Buttons = Loadable({
  loader: () => import('./views/Buttons/Buttons'),
  loading: Loading,
});

// eslint-disable-next-line
const Charts = Loadable({
  loader: () => import('./views/Charts'),
  loading: Loading,
});

// eslint-disable-next-line
const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

// eslint-disable-next-line
const CoreUIIcons = Loadable({
  loader: () => import('./views/Icons/CoreUIIcons'),
  loading: Loading,
});

// eslint-disable-next-line
const Flags = Loadable({
  loader: () => import('./views/Icons/Flags'),
  loading: Loading,
});

// eslint-disable-next-line
const FontAwesome = Loadable({
  loader: () => import('./views/Icons/FontAwesome'),
  loading: Loading,
});

// eslint-disable-next-line
const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});

// eslint-disable-next-line
const Alerts = Loadable({
  loader: () => import('./views/Notifications/Alerts'),
  loading: Loading,
});

// eslint-disable-next-line
const Badges = Loadable({
  loader: () => import('./views/Notifications/Badges'),
  loading: Loading,
});

// eslint-disable-next-line
const Modals = Loadable({
  loader: () => import('./views/Notifications/Modals'),
  loading: Loading,
});

// eslint-disable-next-line
const Colors = Loadable({
  loader: () => import('./views/Theme/Colors'),
  loading: Loading,
});

// eslint-disable-next-line
const Typography = Loadable({
  loader: () => import('./views/Theme/Typography'),
  loading: Loading,
});

// eslint-disable-next-line
const Widgets = Loadable({
  loader: () => import('./views/Widgets/Widgets'),
  loading: Loading,
});



const Home = Loadable({
  loader: () => import('./views/Home/Home'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./views/Profile/Profile'),
  loading: Loading,
});
const Tailoring = Loadable({
  loader: () => import('./views/Tailoring/Tailoring'),
  loading: Loading,
});
const NewsList = Loadable({
  loader: () => import('./views/News/NewsList'),
  loading: Loading,
});

const NewsDetail = Loadable({
  loader: () => import('./views/News/NewsDetail'),
  loading: Loading,
});

const VideoList = Loadable({
  loader: () => import('./views/Video/VideoList'),
  loading: Loading,
});
const VideoDetail = Loadable({
  loader: () => import('./views/Video/VideoDetail'),
  loading: Loading,
});

const CatalogList = Loadable({
  loader: () => import('./views/Catalog/CatalogList'),
  loading: Loading,
});
const CatalogDetail = Loadable({
  loader: () => import('./views/Catalog/CatalogDetail'),
  loading: Loading,
});

const ListingList = Loadable({
  loader: () => import('./views/Listing/ListingList'),
  loading: Loading,
});
const ListingDetail = Loadable({
  loader: () => import('./views/Listing/ListingDetail'),
  loading: Loading,
});

const CategoryList = Loadable({
  loader: () => import('./views/Category/CategoryList'),
  loading: Loading,
});
const CategoryDetail = Loadable({
  loader: () => import('./views/Category/CategoryDetail'),
  loading: Loading,
});

const ProductList = Loadable({
  loader: () => import('./views/Product/ProductList'),
  loading: Loading,
});
const ProductDetail = Loadable({
  loader: () => import('./views/Product/ProductDetail'),
  loading: Loading,
});

const PushNotification = Loadable({
  loader: () => import('./views/Tools/PushNotification'),
  loading: Loading,
});

/*const MaterialsCategoryList = Loadable({
  loader: () => import('./views/MaterialsCategory/MaterialsCategoryList'),
  loading: Loading,
});
const MaterialsCategoryDetail = Loadable({
  loader: () => import('./views/MaterialsCategory/MaterialsCategoryDetail'),
  loading: Loading,
});*/

// eslint-disable-next-line
const MaterialsList = Loadable({
  loader: () => import('./views/Materials/MaterialsList'),
  loading: Loading,
});

// eslint-disable-next-line
const MaterialsDetail = Loadable({
  loader: () => import('./views/Materials/MaterialsDetail'),
  loading: Loading,
});

// eslint-disable-next-line
const AppsList = Loadable({
  loader: () => import('./views/Apps/AppsList'),
  loading: Loading,
});

// eslint-disable-next-line
const Login = Loadable({
  loader: () => import('./views/Pages/Login/Login'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  { path: '/app', exact: true, name: 'App', component: AppsList },
  { path: '/home', exact: true, name: 'Home page', component: Home },
  { path: '/profile', exact: true, name: 'Profilo aziendale', component: Profile },
  { path: '/news', exact: true, name: 'News', component: NewsList },
  { path: '/tailoring', exact: true, name: 'Sartorialità', component: Tailoring },

  { path: '/news/create', exact: true, name: 'News', component: NewsDetail },
  { path: '/news/update/:id', exact: true, name: 'News', component: NewsDetail },

  { path: '/video', exact: true, name: 'Video', component: VideoList },
  { path: '/video/create', exact: true, name: 'Video', component: VideoDetail },
  { path: '/video/update/:id', exact: true, name: 'Video', component: VideoDetail },

  { path: '/catalog', exact: true, name: 'Catalogo', component: CatalogList },
  { path: '/catalog/create', exact: true, name: 'Catalogo', component: CatalogDetail },
  { path: '/catalog/update/:id', exact: true, name: 'Catalogo', component: CatalogDetail },

  { path: '/listing', exact: true, name: 'Listing', component: ListingList },
  { path: '/listing/create', exact: true, name: 'Listing', component: ListingDetail },
  { path: '/listing/update/:id', exact: true, name: 'Listing', component: ListingDetail },

  { path: '/category', props: { type: 'product_category' }, exact: true, name: 'Category', component: CategoryList },
  { path: '/category/create', props: { type: 'product_category' }, exact: true, name: 'Category', component: CategoryDetail },
  { path: '/category/update/:id', props: { type: 'product_category' }, exact: true, name: 'Category', component: CategoryDetail },

  { path: '/product', exact: true, name: 'Product', component: ProductList },
  { path: '/product/create', exact: true, name: 'Product', component: ProductDetail },
  { path: '/product/update/:id', exact: true, name: 'Product', component: ProductDetail },

  { path: '/material-category', props: { type: 'material_category' }, exact: true, name: 'Materials Category', component: CategoryList },
  { path: '/material-category/create', props: { type: 'material_category' }, exact: true, name: 'Materials Category', component: CategoryDetail },
  { path: '/material-category/update/:id', props: { type: 'material_category' }, exact: true, name: 'Materials Category', component: CategoryDetail },
  
  { path: '/material', exact: true, name: 'Materials', component: MaterialsList },
  { path: '/material/create', exact: true, name: 'Materials', component: MaterialsDetail },
  { path: '/material/update/:id', exact: true, name: 'Materials', component: MaterialsDetail },
  
  { path: '/push-notification', exact: true, name: 'Push Notification', component: PushNotification },

  // { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  // { path: '/theme', exact: true, name: 'Theme', component: Colors },
  // { path: '/theme/colors', name: 'Colors', component: Colors },
  // { path: '/theme/typography', name: 'Typography', component: Typography },
  // { path: '/base', exact: true, name: 'Base', component: Cards },
  // { path: '/base/cards', name: 'Cards', component: Cards },
  { path: '/base/forms', name: 'Forms', component: Forms },
  // { path: '/base/switches', name: 'Switches', component: Switches },
  // { path: '/base/tables', name: 'Tables', component: Tables },
  // { path: '/base/tabs', name: 'Tabs', component: Tabs },
  // { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
  // { path: '/base/carousels', name: 'Carousel', component: Carousels },
  // { path: '/base/collapses', name: 'Collapse', component: Collapses },
  // { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
  // { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
  // { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
  // { path: '/base/navbars', name: 'Navbars', component: Navbars },
  // { path: '/base/navs', name: 'Navs', component: Navs },
  // { path: '/base/paginations', name: 'Paginations', component: Paginations },
  // { path: '/base/popovers', name: 'Popovers', component: Popovers },
  // { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
  // { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
  // { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
  // { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
  // { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
  // { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
  // { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
  // { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
  // { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
  // { path: '/icons/flags', name: 'Flags', component: Flags },
  // { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
  // { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  // { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
  // { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
  // { path: '/notifications/badges', name: 'Badges', component: Badges },
  // { path: '/notifications/modals', name: 'Modals', component: Modals },
  // { path: '/widgets', name: 'Widgets', component: Widgets },
  // { path: '/charts', name: 'Charts', component: Charts },
];

export default routes;
