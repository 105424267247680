import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { Form } from 'reactstrap';
import axios from 'axios';

import Config from '../../../components/Config'
import User from '../../../components/User'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkLogin = this.checkLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.checkLogin();
  }

  checkLogin() {
    const user = localStorage.getItem('user');
    if (user && !user.error) {
      axios.post(Config.apiEndpoint+'admin/token', { token: user })
        .then((logged) => {
          if (logged) {
            User.isGuest = false;
            window.location = "/#/app";
          } else {
            console.log('Not logged in');
          }
        }).catch((error) => {
          console.log('Not logged in');
        });
    } else {
      console.log('Not logged in');
    }
  }

  handleChange(event) {
    var state = {};
    state[event.target.name] = event.target.value;
    this.setState(state);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    const username = data.get('username');
    const password = data.get('password');

    axios.post(Config.apiEndpoint+'admin/login', { username, password })
      .then((token) => {
        console.log(token);
        localStorage.setItem('user', token.data);
        User.isGuest = false;
        window.location = "/#/app";
      }).catch((error) => {
        console.log(error);
        alert("Username o password errati");
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <h1>Login</h1>
                    <p className="text-muted">Accedi al tuo account</p>
                    <Form id="login-form" onSubmit={this.handleSubmit}>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Username" name={"username"}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" name={"password"}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Password dimenticata?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2> </h2>
                      <p></p>
                      {/*<Button color="primary" className="mt-3" active>Register Now!</Button>*/}
                      <img alt="Thumbnail" src="/assets/img/logo.png" className=".img-thumbnail"/>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
